import React from 'react';
import styled, { css } from 'styled-components';
import ProgressBar from './ProgressBar';
import findRewardContentStyle from './../utils';
import { MISSIONS_COUNTER } from 'shared/screens/Missions/constants';
import generateMissionCardDataRendering from 'shared/screens/Missions/utils/generateMissionCardDataRendering';
import useMissionsActions from 'shared/screens/Missions/hooks/useMissionsActions';
import { counterTypes, normalizeTimestamp } from 'shared/utils/TimestampManager';
import TimeRemainingText from '../../../components/TimeRemainingText';
import {
  hasGameIds,
  hasGameIdsAndRunningStatus,
  hasGrandType,
  hasRunningStatus,
  hasMysteryRewardTypeNoRevealed,
} from 'shared/screens/Missions/utils/missionsPerformance';
import { MissionTutorialStepsKeys } from 'shared/utils/tutorial';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { isNotStandaloneMobilePWA } from '../../../utils/mobileSupported';
import { isSmallerScreenHeight } from '../utils';

const MissionCard = props => {
  const { object, tutorialStep, activeTutorial, missionsAssets, clientProgress } = props;
  const { missionStatus, reward, endTimestamp } = object.item;
  const missionHasRunningStatus = hasRunningStatus(object.item);
  const missionHasGameIdsAndRunningStatus = hasGameIdsAndRunningStatus(object.item);

  const {
    GrandPrizeCard = null,
    BackgroundCard,
    FrameCard,
    BottomCard,
    Image,
    Text,
    RewardType,
    RewardCoinImage,
    RewardValue,
    Description,
  } = generateMissionCardDataRendering(object.item);

  const { cardBottomText, cardBottomStyle, mainActionHandlers, isLoading } = useMissionsActions(props, Description);

  const opacityCard =
    (!GrandPrizeCard && activeTutorial && tutorialStep === MissionTutorialStepsKeys.COMPLETE_REQUESTED) ||
    (object.index !== 1 && activeTutorial && tutorialStep === MissionTutorialStepsKeys.START_MISSION);

  const strokeColor = missionHasRunningStatus ? '#255ADC' : '#643A9F';
  const { dynamicStyle, iconSizeMultiplier } = findRewardContentStyle(
    RewardValue,
    hasMysteryRewardTypeNoRevealed(reward),
    RewardCoinImage
  );

  const handlePress = () => {
    if (isLoading) return;
    mainActionHandlers[missionStatus]();
  };

  return (
    <Card shouldRunAnimation={missionHasRunningStatus} opacityCard={opacityCard}>
      <Container onClick={handlePress} disabled={isLoading}>
        {GrandPrizeCard && <GrandPrize src={GrandPrizeCard} />}
        <CardFrame src={FrameCard} />
        <CardBackground src={BackgroundCard} />
        <TimeLeftText>
          {isNotStandaloneMobilePWA && <TimeLeftIcon src={missionsAssets.MISSION_TIMER_ICON} />}
          <TimeRemainingText
            prefix={isNotStandaloneMobilePWA ? '' : getText(TEXT_KEY.TIME_REMAINING_PREFIX)}
            style={isNotStandaloneMobilePWA ? Styles.timerTextStylesWithIcon : Styles.timerTextStyles}
            strokeColor={strokeColor}
            until={normalizeTimestamp(endTimestamp)}
            counterType={GrandPrizeCard ? counterTypes.MISSION_GRAND_CARD : counterTypes.MISSION_CARD}
          />
        </TimeLeftText>
        <CardShadow src={missionsAssets.CARD_SHADOW} />
        <CardBottom src={BottomCard} cardBottomStyle={cardBottomStyle} />
        <CardImage src={Image} renderImage={object.item} shouldRunAnimation={missionHasGameIdsAndRunningStatus} />
        {!GrandPrizeCard && <CardImageText src={Text} shouldRunAnimation={missionHasGameIdsAndRunningStatus} />}
        <ProgressBar
          {...object.item}
          shouldRunShineAnimation={!!(missionHasRunningStatus || GrandPrizeCard)}
          missionsAssets={missionsAssets}
          clientProgress={clientProgress}
        />
        <RewardText hasMysteryReward={hasMysteryRewardTypeNoRevealed(reward)}>
          <RewardWinText
            dynamicStyle={dynamicStyle}
            strokeColor={strokeColor}
            hasMysteryReward={hasMysteryRewardTypeNoRevealed(reward)}
          >
            {RewardType}
          </RewardWinText>
          {RewardValue && (
            <RewardCoin>
              {RewardCoinImage && <CoinImage iconSizeMultiplier={iconSizeMultiplier} src={RewardCoinImage} />}
              <RewardCoinText dynamicStyle={dynamicStyle} strokeColor={strokeColor}>
                {RewardValue}
              </RewardCoinText>
            </RewardCoin>
          )}
        </RewardText>
        <DescriptionTextContainer>
          <DescriptionText cardBottomStyle={cardBottomStyle}>{cardBottomText}</DescriptionText>
        </DescriptionTextContainer>
      </Container>
    </Card>
  );
};

export default MissionCard;

const cardTotalHeightVh = isNotStandaloneMobilePWA ? (isSmallerScreenHeight ? 60 : 65) : 80;

const Styles = {
  timerTextStyles: {
    fontSize: '2.5vh',
    fontWeight: '700',
  },
  timerTextStylesWithIcon: {
    fontSize: '2.9vh',
    fontWeight: '700',
    position: 'relative',
    bottom: '0.2rem',
  },
};

const Card = styled.div`
  height: 100%;
  padding-top: 2vh;
  margin-right: ${isNotStandaloneMobilePWA ? 10 : -6}px;
  opacity: ${({ opacityCard }) => (opacityCard ? 0.2 : 1)};

  ${({ shouldRunAnimation }) =>
    shouldRunAnimation &&
    `
  animation-duration: 7s;
  animation-name: frameZoom;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;

  @keyframes frameZoom {
    0% {
      transform: scale(1);
    }

    10% {
      transform: scale(1.06);
    }

    12% {
      transform: scale(1.06);
    }

    24% {
      transform: scale(1);
    }

    100% {
      transform: scale(1);
    }
    `}
`;

const Container = styled.div`
  position: relative;
  display: inline-block;
  width: ${isNotStandaloneMobilePWA ? 34 : 44}vh;
  height: ${cardTotalHeightVh - 13.4}vh;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

const GrandPrize = styled.img`
  z-index: -1;
  position: absolute;
  top: -${cardTotalHeightVh / 8.9}vh;
  left: 1.6%;
  width: 100%;
  height: ${isNotStandaloneMobilePWA ? cardTotalHeightVh - 5 : cardTotalHeightVh}vh;
`;

const CardBackgroundSmall = css`
  height: ${cardTotalHeightVh - 19}vh;
  margin-top: -1vh;
  width: 93%;
`;

const CardBackground = styled.img`
  width: 90%;
  height: ${cardTotalHeightVh - 20}vh;
  top: 35px;
  margin-left: 1.4vh;
  z-index: 3;
  ${isNotStandaloneMobilePWA && CardBackgroundSmall};
`;

const CardFrameSmall = css`
  top: -5%;
  right: 0px;
  width: 96%;
`;

const CardFrame = styled.img`
  position: absolute;
  top: -3.2%;
  right: 1%;
  width: 95%;
  z-index: 4;
  ${isNotStandaloneMobilePWA && CardFrameSmall};
`;

const TimeLeftIcon = styled.img`
  height: 1.1rem;
  width: 1.1rem;
  margin-right: 3px;
  margin-top: 6px;
`;

const TimeLeftText = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: center;
  justify-content: center;
  top: ${isNotStandaloneMobilePWA ? (isSmallerScreenHeight ? -0.2 : 0) : 0.8}rem;
  left: ${isNotStandaloneMobilePWA ? 0.1 : 0.4}rem;
  z-index: 3;
`;

const CardShadowSmall = css`
  width: 93%;
  left: 2vh;
  bottom: ${isSmallerScreenHeight ? -4 : -3}%;
`;

const CardShadow = styled.img`
  width: 91%;
  height: 20vh;
  left: 2.8vh;
  position: absolute;
  bottom: -2.5%;
  z-index: 4;
  ${isNotStandaloneMobilePWA && CardShadowSmall};
`;

const CardBottomSmall = css`
  width: 92%;
  height: 16vh;
  left: 2vh;
  bottom: ${isSmallerScreenHeight ? -1 : 0}%;
`;

const CardBottom = styled.img`
  width: 89.5%;
  height: 20vh;
  left: 3.1vh;
  position: absolute;
  bottom: -3%;
  z-index: ${props => (props.cardBottomStyle ? 4 : -1)};
  ${isNotStandaloneMobilePWA && CardBottomSmall};
`;

const CardImage = styled.img`
  position: absolute;
  z-index: 3;
  ${({ renderImage }) => cardImageStyle(renderImage)}

  ${({ shouldRunAnimation }) =>
    shouldRunAnimation &&
    `
  animation-duration: 1500ms;
  animation-name: jump;
  animation-fill-mode: forwards;
  animation-delay: 2s;

  @keyframes jump {
    50% {
      transform: translate(0vh, -8vh) scale(1.18);
      z-index: 3;
    }

    51% {
      z-index: 4;
    }

    90%, 100% {
      transform: translate(0vh, -2vh) scale(1.08);
      z-index: 4;
    }
  }`}
`;

const CardImageText = styled.img`
  position: absolute;
  top: ${isNotStandaloneMobilePWA ? 14 : 24}vh;
  left: ${isNotStandaloneMobilePWA ? 5.2 : 2.2}vh;
  height: ${isNotStandaloneMobilePWA ? 12 : 18}vh;
  width: ${isNotStandaloneMobilePWA ? 24 : 41}vh;
  z-index: 3;

  ${({ shouldRunAnimation }) =>
    shouldRunAnimation &&
    `
  animation-duration: 1500ms;
  animation-name: transparency;
  animation-fill-mode: forwards;
  animation-delay: 2s;

  @keyframes transparency {
    10% {
      transform: scale(1);
      opacity: 1;
    }

    40% {
      opacity: 0.7;
      transform: translate(0vh, 2vh) scale(0.9);
    }

    100% {
      opacity: 0.5;
      transform: translate(0vh, 2vh) scale(0.9);
    }
  }`}
`;

const CoinImage = styled.img`
  bottom: 8vh;
  margin-right: 3px;
  height: ${({ iconSizeMultiplier }) => iconSizeMultiplier * 5}vh;
  left: 6vh;
  width: ${({ iconSizeMultiplier }) => iconSizeMultiplier * 4.5}vh;
`;

const RewardTextSmall = css`
  bottom: ${({ hasMysteryReward }) =>
    hasMysteryReward ? (isSmallerScreenHeight ? '5.5vh' : '6vh') : isSmallerScreenHeight ? '-0.5vh' : '0vh'};
  left: 0vh;
  width: 35vh;
`;

const RewardText = styled.div`
  align-items: center;
  bottom: ${({ hasMysteryReward }) => (hasMysteryReward ? '6.5vh' : '1vh')};
  display: flex;
  flex-direction: column;
  left: 3vh;
  position: absolute;
  width: 40vh;
  z-index: 3;
  ${isNotStandaloneMobilePWA && RewardTextSmall};
`;

const RewardWinText = styled.p`
  bottom: ${({ hasMysteryReward }) => (hasMysteryReward ? '7vh' : '8vh')};
  color: white;
  font-size: ${({ dynamicStyle }) => dynamicStyle.winFontSize};
  font-style: italic;
  font-weight: 800;
  left: ${({ hasMysteryReward }) => (hasMysteryReward ? '5vh' : '19vh')};
  -webkit-text-stroke: 1.5px ${({ strokeColor }) => strokeColor};
  text-stroke: 1.5px ${({ strokeColor }) => strokeColor};
  margin-bottom: ${({ dynamicStyle }) => dynamicStyle.winMarginBottom};
  letter-spacing: -1px;
`;

const RewardCoinText = styled.p`
  margin-bottom: ${({ dynamicStyle }) => dynamicStyle.marginBottom};
  bottom: 5vh;
  color: white;
  font-size: ${({ dynamicStyle }) => dynamicStyle.fontSize};
  font-weight: 800;
  left: 3vh;
  letter-spacing: 0px;
  -webkit-text-stroke: 1.5px ${({ strokeColor }) => strokeColor};
  text-stroke: 1.5px ${({ strokeColor }) => strokeColor};
  word-break: break-all;
  white-space: normal;
  line-height: ${({ dynamicStyle }) => dynamicStyle.lineHeight};
`;

const RewardCoin = styled.div`
  bottom: 3vh;
  display: flex;
  flex-direction: row;
  margin-top: -6px;
  position: relative;
  padding: 0px 10px;
`;

const DescriptionTextContainer = styled.div`
  z-index: 5;
  text-align: center;
  align-content: center;
  align-items: center;
  bottom: 1vh;
  margin-left: '5vh';
  width: '10vh';
`;

const DescriptionText = styled.p`
  align-content: center;
  bottom: ${props => (props.cardBottomStyle ? '0.5vh' : isNotStandaloneMobilePWA ? '-10px' : '-16px')};
  color: white;
  font-size: ${isNotStandaloneMobilePWA ? 2 : 2.5}vh;
  font-style: italic;
  font-weight: 700;
  left: 1.5vh;
  line-height: 3vh;
  padding-left: 3vh;
  padding-right: 3vh;
  position: absolute;
  text-align: center;
  text-shadow: 0 0px 2px #000;
  white-space: initial;
  z-index: 5;
`;

const MissionsRegularImageStyle = css`
  top: ${isNotStandaloneMobilePWA ? (isSmallerScreenHeight ? 7 : 8) : 12}vh;
  right: ${isNotStandaloneMobilePWA ? 6 : 1.5}vh;
  height: ${isNotStandaloneMobilePWA ? 21 : 35.8}vh;
  width: ${isNotStandaloneMobilePWA ? 65 : 89.6}%;
`;

const cardImageStyle = ({ missionType, counter }) =>
  counter.params !== null && hasGameIds(counter.params.gameIds)
    ? `
    top: ${isNotStandaloneMobilePWA ? (isSmallerScreenHeight ? 4 : 5) : 9}vh;
    right: ${isNotStandaloneMobilePWA ? 4 : 5.4}vh;
    width: ${isNotStandaloneMobilePWA ? 25.5 : 31.5}vh;
    `
    : hasGrandType(missionType)
      ? `
    top: ${isNotStandaloneMobilePWA ? 4 : 5}vh;
    right: ${isNotStandaloneMobilePWA ? 6 : 5.4}vh;
    width: ${isNotStandaloneMobilePWA ? 21 : 31.5}vh;
   `
      : MISSIONS_IMAGES_STYLES[counter.counterType];

const MISSIONS_IMAGES_STYLES = {
  [MISSIONS_COUNTER.COMPLETE_MISSION_X_TIMES]: `
    top: ${isNotStandaloneMobilePWA ? (isSmallerScreenHeight ? 6 : 7) : 4}vh;
    right: ${isNotStandaloneMobilePWA ? 3 : 2.1}vh;
    height: ${isNotStandaloneMobilePWA ? 21 : 45.5}vh;
    width: ${isNotStandaloneMobilePWA ? 70 : 89}%;
    `,
  [MISSIONS_COUNTER.SPIN_X_TIMES]: `
    top: ${isNotStandaloneMobilePWA ? (isSmallerScreenHeight ? 7 : 8) : 12}vh;
    right: ${isNotStandaloneMobilePWA ? 4 : 6}vh;
    height: ${isNotStandaloneMobilePWA ? 22 : 25.4}vh;
    width: 70%;
    `,
  [MISSIONS_COUNTER.SPIN_X_TIMES_Y_GAMES]: `
    top: ${isNotStandaloneMobilePWA ? (isSmallerScreenHeight ? 7 : 8) : 11.7}vh;
    right: ${isNotStandaloneMobilePWA ? 4 : 6}vh;
    height: ${isNotStandaloneMobilePWA ? 22 : 28.4}vh;
    width: 70%;
    `,
  [MISSIONS_COUNTER.WIN_X_TIMES]: MissionsRegularImageStyle,
  [MISSIONS_COUNTER.WIN_X_TIMES_Y_GAMES]: MissionsRegularImageStyle,
  [MISSIONS_COUNTER.WIN_X_AMOUNT]: MissionsRegularImageStyle,
  [MISSIONS_COUNTER.WIN_X_AMOUNT_Y_GAMES]: MissionsRegularImageStyle,
  [MISSIONS_COUNTER.WAGE_X_AMOUNT]: MissionsRegularImageStyle,
  [MISSIONS_COUNTER.LEVEL_UP_X_TIMES]: MissionsRegularImageStyle,
  [MISSIONS_COUNTER.MAX_BET_X_TIMES]: MissionsRegularImageStyle,
  [MISSIONS_COUNTER.COLLECT_BONUS_X_TIMES]: `
    top: ${isNotStandaloneMobilePWA && isSmallerScreenHeight ? 6 : 7}vh;
    right: ${isNotStandaloneMobilePWA ? 3 : 1.5}vh;
    height: ${isNotStandaloneMobilePWA ? 21 : 35}vh;
    width: ${isNotStandaloneMobilePWA ? 70 : 89}%;
    `,
};

