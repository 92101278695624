export const BannerHeightMultiSlides = [
  [600, 31],
  [660, 36],
  [760, 41],
];

export const BannerWidthMultiSlides = [
  [600, 12],
  [660, 15],
  [760, 17],
];

export const BannerTopPositionMultiSlides = [
  [600, 0.3],
  [660, 0.5],
  [760, 0.7],
];

export const BannerDetailTopMultiSlides = [
  [600, -0.4],
  [660, -0.3],
  [760, -0.2],
];
export const BannerDetailHeightMultiSlides = [
  [600, 30],
  [660, 36],
  [760, 40],
];

export const BannerDetailHeightSingleSlide = [
  [600, 31],
  [660, 34],
  [760, 41],
];

export const BannerDetailTopSingleSlide = [
  [600, -0.5],
  [660, 0],
  [760, -0.2],
];

export const BannerDetailWidthMultiSlides = [
  [600, 20],
  [760, 25],
];

export const BannerDetailGameIconHeight = [
  [600, 15],
  [660, 17],
  [760, 21],
];

export const BannerDetailGameIconWidth = [
  [600, 7],
  [660, 9],
  [760, 11],
];

