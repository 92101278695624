import React from 'react';
import styled, { css } from 'styled-components';
import { isNotStandaloneMobilePWA } from '../../../../utils/mobileSupported';

const ProgressBarShineAnimation = ({ shineAsset }) => {
  return (
    <Container>
      <ShineImage src={shineAsset} />
    </Container>
  );
};

const ContainerSmall = css`
  width: 27vh;
  height: 4vh;
  left: 4vh;
  bottom: 15.4vh;
`;

const Container = styled.div`
  position: absolute;
  overflow: hidden;
  bottom: 19.4vh;
  left: 5.4vh;
  height: 4.5vh;
  width: 35vh;
  border-radius: 2.2vh;
  z-index: 5;
  ${isNotStandaloneMobilePWA && ContainerSmall}
`;

const ShineImageSmall = css`
  width: 28vh;
  height: 4.1vh;
  bottom: 15vh;
`;

const ShineImage = styled.img`
  bottom: 19vh;
  margin-left: -70vh;
  height: 4.6vh;
  width: 36vh;
  z-index: 5;

  animation-duration: 6500ms;
  animation-name: progressBarShine;
  animation-iteration-count: infinite;
  ${isNotStandaloneMobilePWA && ShineImageSmall}

  @keyframes progressBarShine {
    0% {
      transform: translateX(0);
    }

    30% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(70vh);
    }

    52% {
      transform: translateX(70vh);
    }

    72% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(0);
    }
  }
`;

export default ProgressBarShineAnimation;

