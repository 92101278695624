import theme from 'shared/assets/style/theme';
import defaultTheme from './defaultTheme';
import { isNotStandaloneMobilePWA, pwaStyleAdapter } from '../utils/mobileSupported';
import { height as NavHeight } from 'shared/components/NavigationBar/sizes';

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

const SgaTheme = {
  ...defaultTheme,
  LimitedTimeOffer: {
    ...defaultTheme.LimitedTimeOffer,
    BannerContainer: {
      ...defaultTheme.LimitedTimeOffer.BannerContainer,
      marginBottom: '2%',
    },
    BuyButton: {
      ...defaultTheme.LimitedTimeOffer.BuyButton,
      transform: 'translateX(1%)',
    },
  },
  MessageDetails: {
    ...defaultTheme.MessageDetails,
    MessageTitle: {
      ...defaultTheme.MessageDetails.MessageTitle,
      marginBottom: '0.5rem',
    },
  },
  NavigationBar: {
    ...defaultTheme.NavigationBar,
    LoyaltyCurrencyWrapperStyle: {
      ...defaultTheme.NavigationBar.LoyaltyCurrencyWrapperStyle,
      right: windowWidth < 1400 ? 'calc(50% - 185px)' : 'calc(50% - 200px)',
    },
    LevelLabel: {
      ...defaultTheme.NavigationBar.LevelLabel,
      paddingBottom: 1,
    },
    TopBar: {
      ...defaultTheme.NavigationBar.TopBar,
      height: '5.25rem',
    },
    FullButton: {
      ...defaultTheme.NavigationBar.FullButton,
      top: '-0.25rem',
    },
  },
  LobbyOverlay: {
    ...defaultTheme.LobbyOverlay,
    MobileModalDefaultStyles: {
      ...defaultTheme.LobbyOverlay.MobileModalDefaultStyles,
      top: isNotStandaloneMobilePWA ? (windowHeight > 330 ? '6vh' : '8vh') : '5vh',
    },
    MainSceneContainer: {
      ...defaultTheme.LobbyOverlay.MainSceneContainer,
      paddingTop: isNotStandaloneMobilePWA ? '4.5rem' : NavHeight,
    },
  },
  Start: {
    ...defaultTheme.Start,
    BottomBarFooterText: {
      ...defaultTheme.Start.BottomBarFooterText,
      marginTop: 5,
      marginBottom: 0,
    },
    MiddleSectionWrapper: {
      ...defaultTheme.Start.MiddleSectionWrapper,
      paddingInlineStart: '0.5rem',
    },
    MiddleLayoutButtonContainer: {
      ...defaultTheme.Start.MiddleLayoutButtonContainer,
      marginTop: '5rem',
    },
  },
  DefaultTooltip: {
    ...defaultTheme.DefaultTooltip,
    Border: {
      ...defaultTheme.DefaultTooltip.Border,
      stopColor1: '#edcf68',
      stopColor2: '#edcf68',
    },
    Background: {
      ...defaultTheme.DefaultTooltip.Background,
      stopColor1: '#7d411e',
      stopColor2: '#791515',
    },
  },
  BottomBar: {
    ...defaultTheme.BottomBar,
    NotificationText: {
      ...defaultTheme.BottomBar.NotificationText,
      paddingTop: '15%',
    },
    NotificationEvents: {
      ...defaultTheme.BottomBar.NotificationEvents,
      paddingTop: '0.83rem',
    },
  },
  RewardListItem: {
    ...defaultTheme.RewardListItem,
    OfferNameText: {
      ...defaultTheme.RewardListItem.OfferNameText,
      paddingTop: 4,
    },
    LoyaltyPointsText: {
      ...defaultTheme.RewardListItem.LoyaltyPointsText,
      paddingTop: 5,
    },
  },
  Tier: {
    ...defaultTheme.Tier,
    Card: {
      ...defaultTheme.Tier.Card,
      height: 60,
      width: 85,
    },
    TierWrapper: {
      ...defaultTheme.Tier.TierWrapper,
      marginRight:
        windowWidth < 1300 ? '85px !important' : windowWidth < 1400 ? '105px !important' : '103px !important',
    },
    CardAnimation: {
      ...defaultTheme.Tier.CardAnimation,
      left: -30,
    },
  },
  DailySpin: {
    ...defaultTheme.DailySpin,
    WheelItems: {
      ...defaultTheme.DailySpin.WheelItems,
      fontSize: windowWidth < 1400 ? '1.8rem' : '2rem',
    },
    StreakDaysRowNumber: {
      ...defaultTheme.DailySpin.StreakDaysRowNumber,
      paddingRight: 1,
      paddingTop: 4,
      paddingBottom: 2,
    },
    StreakDaysRowBigNumber: {
      ...defaultTheme.DailySpin.StreakDaysRowBigNumber,
      paddingRight: 1,
      paddingTop: 4,
      paddingBottom: 2,
    },
    StreakDaysRowValues: {
      ...defaultTheme.DailySpin.StreakDaysRowValues,
      marginTop: 5,
      lineHeight: 0,
    },
    MilestoneCoinsValue: {
      ...defaultTheme.DailySpin.MilestoneCoinsValue,
      marginBottom: 0,
    },
    MilestoneLoyaltyValue: {
      ...defaultTheme.DailySpin.MilestoneLoyaltyValue,
      marginBottom: -7,
    },
    MilestoneCircleValue: {
      ...defaultTheme.DailySpin.MilestoneCircleValue,
      marginLeft: 0,
      marginTop: windowWidth < 1300 ? '1%' : windowWidth < 1500 ? '2.5%' : '1%',
    },
    BottomRightCoinImage: {
      ...defaultTheme.DailySpin.BottomRightCoinImage,
      marginBottom: 5,
    },
    DailyWheelCollectButton: {
      ...defaultTheme.DailySpin.DailyWheelCollectButton,
      paddingBottom: 0,
    },
    BottomBarBackgroundWrapperImage: {
      ...defaultTheme.DailySpin.BottomBarBackgroundWrapperImage,
      height: '105%',
    },
  },
  CoinItem: {
    ...defaultTheme.CoinItem,
    CoinAmount: {
      ...defaultTheme.CoinItem.CoinAmount,
      fontSize: '25px',
    },
  },
  CreditCardDetails: {
    ...defaultTheme.CreditCardDetails,
    CheckBoxBackgroundImage: {
      ...defaultTheme.CreditCardDetails.CheckBoxBackgroundImage,
      width: '40%',
    },
    CvvTooltipArrowOver: {
      ...defaultTheme.CreditCardDetails.CvvTooltipArrowOver,
      bottom: -27,
    },
  },
  MissionsProgress: {
    ...defaultTheme.MissionsProgress,
    TimeRemainingTextWithoutCard: {
      ...defaultTheme.MissionsProgress.TimeRemainingTextWithoutCard,
      top: 43,
    },
    TextProgress: {
      ...defaultTheme.MissionsProgress.TextProgress,
      marginBottom: '-4px',
    },
  },
  ShopScreen: {
    ...defaultTheme.ShopScreen,
    ShopContainer: {
      ...defaultTheme.ShopScreen.ShopContainer,
      PackagesContainer: {
        ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer,
        padding: '6% 5.5rem 0.4rem',
      },
    },
  },
  Events: {
    ...defaultTheme.Events,
    TimeLeftText: {
      ...defaultTheme.Events.TimeLeftText,
      marginTop: '0.4rem',
    },
  },
  BonusLinkMessage: {
    ...defaultTheme.BonusLinkMessage,
    LPBonusContainer: {
      ...defaultTheme.BonusLinkMessage.LPBonusContainer,
      paddingTop: '360px',
    },
    CoinsBonusContainer: {
      ...defaultTheme.BonusLinkMessage.CoinsBonusContainer,
      paddingTop: '370px',
    },
  },
  SideBar: {
    ...defaultTheme.SideBar,
    MenuContainer: {
      ...defaultTheme.SideBar.MenuContainer,
      border: '5px solid #bf3857',
      background: 'linear-gradient(to bottom, #551621 0%, #6A1B29 25%, #902639 50%, #6A1B29 75%, #551621 100%)',
    },
  },
  OfferDetail: {
    ...defaultTheme.OfferDetail,
    CarouselIndicator: {
      ...defaultTheme.OfferDetail.CarouselIndicator,
      backgroundColor: theme.palette.common[3],
      border: 0,
    },
    CarouselIndicatorActive: {
      ...defaultTheme.OfferDetail.CarouselIndicatorActive,
      background: `linear-gradient(
        to bottom, 
        ${theme.palette.primary[24]} 0%,  
        ${theme.palette.primary[15]} 35%, 
        ${theme.palette.primary[15]} 65%, 
        ${theme.palette.primary[24]} 100%
      )`,
      border: 0,
    },
  },
  Bar: {
    ...defaultTheme.Bar,
    BarContainer: {
      ...defaultTheme.Bar.BarContainer,
      background: 'linear-gradient(180deg, #160b08 0%, #703929 100%)',
    },
    BarProgress: {
      ...defaultTheme.Bar.BarProgress,
      background:
        'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .25) 70%,  rgba(255, 255, 255, .4) 100%), linear-gradient(to bottom, rgba(223, 74, 222, 1) 50%, rgba(211, 56, 208, 1) 50%)',
    },
  },
  TimeRemaining: {
    ...defaultTheme.TimeRemaining,
    TimerLabel: {
      ...defaultTheme.TimeRemaining.TimerLabel,
      top: '-0.5rem',
    },
    VIPTimer: {
      ...defaultTheme.TimeRemaining.VIPTimer,
      top: pwaStyleAdapter(5, 'rem'),
    },
  },
  CurrencyDisplay: {
    ...defaultTheme.CurrencyDisplay,
    CoinsLabel: {
      ...defaultTheme.CurrencyDisplay.CoinsLabel,
      paddingBottom: 1,
    },
    LoyaltyLabel: {
      ...defaultTheme.CurrencyDisplay.LoyaltyLabel,
      paddingBottom: 1,
    },
  },
  OfferDetailDescription: {
    ...defaultTheme.OfferDetailDescription,
    ButtonText: {
      ...defaultTheme.OfferDetailDescription.ButtonText,
      paddingBottom: '3.3%',
    },
  },
  LinkAccountForm: {
    ...defaultTheme.LinkAccountForm,
    HeaderTitle: {
      ...defaultTheme.LinkAccountForm.HeaderTitle,
      marginTop: '0.3rem',
    },
    NeedHelpText: {
      ...defaultTheme.LinkAccountForm.NeedHelpText,
      top: '1.4rem',
    },
    Divider: {
      width: '100%',
      marginTop: '2rem',
    },
  },
};

export default SgaTheme;

