import { isAndroid, isIOS, isMobile, isTablet } from 'react-device-detect';
import { PLATFORM } from 'shared/constants';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';

export const isMobileSupported = () => {
  const mobileSupported = ENVIRONMENT_VARIABLES.MOBILE_WEB_SUPPORT || '';

  if (isIOS && mobileSupported.includes(PLATFORM.IOS)) return true;
  if (isAndroid && mobileSupported.includes(PLATFORM.ANDROID)) return true;
  if (!isMobile && mobileSupported.includes(PLATFORM.WEB)) return true;

  return false;
};

export const browserTabOffset = () => window.visualViewport?.pageTop || window.scrollY;

export const isStandalonePWA =
  isMobile && (window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches);

export const isNotStandaloneMobilePWA =
  isMobile && !window.navigator.standalone && !window.matchMedia('(display-mode: standalone)').matches;

export const pwaStyleAdapter = (style, unit = 'px', multiplier = 1.4) => {
  if (!style) return style;

  const scaleDimension = dimension => {
    if (typeof dimension === 'number') return `calc(${dimension}${unit} * ${multiplier})`;

    if (typeof dimension === 'string') {
      const numericValue = parseFloat(dimension);
      const unitValue = dimension.replace(/[0-9.]/g, '').trim() || unit;
      return isNaN(numericValue) ? dimension : `calc(${numericValue}${unitValue} * ${multiplier})`;
    }

    return dimension;
  };

  const ensureUnit = dimension => {
    if (typeof dimension === 'number') return `${dimension}${unit}`;

    if (typeof dimension === 'string') {
      const numericValue = parseFloat(dimension);
      const unitValue = dimension.replace(/[0-9.]/g, '').trim();
      if (!unitValue && !isNaN(numericValue)) {
        return `${numericValue}px`;
      }
      return dimension;
    }

    return dimension;
  };

  const dimensionProcessor = isMobileSupported() && isMobile ? scaleDimension : ensureUnit;

  const propertiesToAdjust = ['height', 'width', 'minHeight', 'maxWidth'];

  if (typeof style === 'string' || typeof style === 'number') return dimensionProcessor(style);

  const adjustedStyle = { ...style };
  for (const prop of propertiesToAdjust) {
    const value = adjustedStyle[prop];
    if (value && value !== '100%') {
      adjustedStyle[prop] = dimensionProcessor(value);
    }
  }

  return adjustedStyle;
};

