import React from 'react';
import styled from 'styled-components';
import SliderDot from './SliderDot';
import { isNotStandaloneMobilePWA } from '../../../utils/mobileSupported';

function SliderIndicator({ lobbyPromotions, selectedSlide, onDotPress }) {
  return (
    <SliderDotWrapper>
      {lobbyPromotions.map((_, index) => (
        <SliderDot index={index} key={index} selected={selectedSlide === index} onPress={onDotPress} size={50} />
      ))}
    </SliderDotWrapper>
  );
}

export default SliderIndicator;

const SliderDotWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -2rem;
  z-index: 200;
  min-width: 300px;
  height: 60px;
  z-index: 9999999;

  @media only screen and (max-height: 790px) {
    height: 40px;
    bottom: ${isNotStandaloneMobilePWA && window.innerHeight < 320 ? -1.2 : -2.2}rem;
  }

  @media only screen and (min-width: 1440px) and (max-width: 1600px) and (min-height: 800px) and (max-height: 900px) {
    bottom: -2.8rem;
  }
`;

