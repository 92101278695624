import { isTablet } from 'react-device-detect';
import { isNotStandaloneMobilePWA, pwaStyleAdapter, isStandalonePWA } from '../../utils/mobileSupported';

const MobileOnlyNotPWA = isNotStandaloneMobilePWA && !isTablet;
const MobileOnlyPWA = isStandalonePWA && !isTablet;
const TabletOnlyPWA = isTablet && isStandalonePWA;
const TabletOnlynotPWA = isTablet && isNotStandaloneMobilePWA;
const Styles = {
  fottetButtonStyle: {
    width:
      window.innerWidth < 1400 && window.innerHeight < 800
        ? isTablet
          ? pwaStyleAdapter(255, 'px', 1.7)
          : pwaStyleAdapter(255, 'px')
        : '295px',
    height:
      window.innerWidth < 1400 && window.innerHeight < 800
        ? isTablet
          ? pwaStyleAdapter(45, 'px', 1.7)
          : pwaStyleAdapter(45, 'px')
        : '55px',
    cursor: 'pointer',
  },
  NoAssetLayoutModalStyle: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: isTablet ? '0 10vmin' : '0 50vmin',
  },
  NoAssetLayoutModalBodyStyle: {
    height: TabletOnlyPWA ? '50%' : TabletOnlynotPWA ? '59%' : MobileOnlyNotPWA ? pwaStyleAdapter(40, '%', 1.7) : MobileOnlyPWA ? pwaStyleAdapter(40, '%', 1.4) : '40%',
  },
  NoAssetLayoutModalContainerStyle: {
    height: isTablet ? '44vh' : MobileOnlyNotPWA ? pwaStyleAdapter(30, 'vh', 1.4) : MobileOnlyPWA ? pwaStyleAdapter(30, 'vh', 1.5) : '30vh',
  },
};

export default Styles;

