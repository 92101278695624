import React, { useState } from 'react';
import RewardCenterCardContainer from './RewardCenterCard';
import generateRewardCenterCardDataRendering from 'shared/screens/RewardCenter/utils/generateRewardCenterCardDataRendering';
import styled from 'styled-components';
import { numberWithCommas } from 'shared/utils/number';
import { getOfferDescription, hasSoldOutState } from 'shared/screens/RewardCenter/utils/validations';

const RewardCenterOffers = ({ object, rewardCenterAssets, RewardsImageBaseURL, setSelectedOffer }) => {
  const { Frame, Banner, Shadow, Loyalty, Coins, Badge } = generateRewardCenterCardDataRendering(
    object,
    rewardCenterAssets
  );
  const { loyaltyPoints, offerImage, ribbontype, isNew } = object;

  const [offerImageLoaded, setOfferImageLoaded] = useState(false);

  return (
    <RewardCenterCardContainer
      handleClick={() => (!hasSoldOutState(ribbontype) ? setSelectedOffer(object) : null)}
      Frame={Frame}
    >
      {Banner && <CardRibbon src={Banner} />}
      {Shadow && <CardShadow src={Shadow} />}
      <CardContent hasRibbon={Banner}>
        {offerImage && RewardsImageBaseURL && (
          <CardImage src={`${RewardsImageBaseURL}${offerImage}`} onLoad={() => setOfferImageLoaded(true)} />
        )}
        {offerImageLoaded && (
          <DescriptionWrapper>
            <>
              <LoyaltyWrapper>
                <LoyaltyIcon src={Loyalty} />
                <LoyaltyText hasSoldOutState={hasSoldOutState(ribbontype)}>
                  {numberWithCommas(loyaltyPoints)}
                </LoyaltyText>
              </LoyaltyWrapper>
              <CoinsWrapper>
                <CoinsIcon src={Coins} />
                <CoinsValue
                  hasSoldOutState={hasSoldOutState(ribbontype)}
                  hasLongDescription={getOfferDescription(object)?.length > 38}
                >
                  {getOfferDescription(object)}
                </CoinsValue>
              </CoinsWrapper>
            </>
          </DescriptionWrapper>
        )}
        {isNew && <NewOfferBadge src={Badge} />}
      </CardContent>
    </RewardCenterCardContainer>
  );
};

export default RewardCenterOffers;

const CardImage = styled.img`
  width: 100%;
  object-fit: cover;
  position: relative;
  z-index: 1;
  border-top-left-radius: 10%;
  border-top-right-radius: 10%;
  height: 84%;
`;

const CardShadow = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: 3;
  opacity: 0.5;
  left: 0rem;
`;

const CardRibbon = styled.img`
  width: 75%;
  bottom: 35rem;
  z-index: 5;
  position: absolute;
  top: -2.3rem;
  left: 2.8rem;
  @media only screen and (max-height: 650px) {
    top: -1.2rem;
    left: 1.4rem;
  }
  @media only screen and (min-height: 651px) and (max-height: 800px) {
    top: -1.5rem;
    left: 2rem;
  }
  @media only screen and (min-height: 801px) and (max-height: 900px) {
    top: -2rem;
    left: 2.5rem;
  }
  @media only screen and (min-height: 901px) and (max-height: 949px) {
    top: -2.3rem;
    left: 3rem;
  }
  @media only screen and (min-height: 950px) {
    top: -2.4rem;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 90%;
`;

const DescriptionWrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  height: 100%;
`;

const LoyaltyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const LoyaltyIcon = styled.img`
  height: 100%;
  width: 15%;
  object-fit: contain;
`;

const LoyaltyText = styled.p`
  font-size: 1.8rem;
  margin-bottom: 0;
  margin-right: 20px;
  background: ${({ hasSoldOutState }) =>
    hasSoldOutState ? '#BCBBBA' : 'linear-gradient(to bottom, #fdc60c 0%, #d78900 48%, #c9841d 52%, #fdc60c 100%)'};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  text-stroke: 1px #000;
  -webkit-text-stroke: 1px #000;
  @media only screen and (max-height: 659px) {
    font-size: 1rem;
  }
  @media only screen and (min-height: 660px) and (max-height: 800px) {
    font-size: 1.5rem;
  }
`;

const CoinsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const CoinsIcon = styled.img`
  height: 1.2rem;
  width: 10%;
  margin-right: 3px;
  object-fit: contain;
`;

const CoinsValue = styled.p`
  font-size: 1.1rem;
  color: ${({ hasSoldOutState }) => (hasSoldOutState ? '#BCBBBA' : '#fff')};
  text-shadow: 0px -1.5px 1px #000;
  font-weight: 600;
  margin-bottom: 0;
  @media only screen and (max-height: 659px) {
    font-size: ${window.innerHeight < 320 ? 0.7 : 0.8}rem;
  }
  @media only screen and (min-height: 660px) and (max-height: 900px) {
    font-size: ${({ hasLongDescription }) =>
      hasLongDescription ? (window.innerHeight < 320 ? '0.7rem' : '0.8rem') : '1rem'};
  }
  @media only screen and (min-height: 950px) {
    font-size: 1.2rem;
  }
`;

const NewOfferBadge = styled.img`
  position: absolute;
  width: 80px;
  height: 80px;
  z-index: 10;
  left: 21rem;
  bottom: 36.2rem;
  filter: drop-shadow(-2px 4px 8px #000000);

  @media only screen and (min-height: 660px) and (max-height: 800px) {
    width: 65px;
    height: 65px;
    left: 12.5rem;
    bottom: 22.2rem;
  }
  @media only screen and (min-height: 801px) and (max-height: 900px) {
    left: 17rem;
    bottom: 29.2rem;
  }
`;

