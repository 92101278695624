import React, { Component } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import SGame from 'shared/screens/Game';
// eslint-disable-next-line no-unused-vars
import GameInterface from 'shared/api/GameInterface';
import { counterTypes } from 'shared/utils/TimestampManager';
import { fetchGameRooms } from 'shared/state/query/games/utils';
import ActivityIndicator from '../../components/ActivityIndicator/ActivityIndicator';
import Logger from 'shared/lib/analytics/logger';
import { EVENT_TYPES } from '../../lib/analytics/constants';
import { ONBOARDING_FLOWS } from 'shared/utils/onboarding';
import CountdownTimer from '../../lib/time/component/CountdownTimer';
import { FTUE_STEPS_KEYS, TAG } from 'shared/constants';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import theme from 'shared/assets/style/theme';
import LazyImage from '../../components/LazyImage/LazyImage';
import { pwaStyleAdapter, isNotStandaloneMobilePWA } from '../../utils/mobileSupported';

export default class Game extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.netEntExtend = null;
    this.isSneakPeek = React.createRef(false);
  }

  /** @type {GameInterface} */
  game = null;
  gameProvider = null;
  gameRef = React.createRef();
  messageHandlerFuncRef = null;
  playableGameAreaHeight = window.innerHeight - 80;

  componentWillUnmount() {
    if (this.game) {
      window.removeEventListener('message', this.messageHandlerFuncRef);
    }
    window.removeEventListener('resize', this.handleResize);
    this.isSneakPeek.current && fetchGameRooms(true);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  messageHandlerFunc(msg) {
    this.game.messageHandle(msg, this.gameProvider);
  }

  handleResize = () => {
    if (this.netEntExtend) {
      const gameWidth = parseInt(document.getElementById('gameArea').offsetWidth);
      const gameHeight = parseInt(document.getElementById('gameArea').offsetHeight);
      const gameAspectRatio = gameWidth / gameHeight;
      const newHeight = this.playableGameAreaHeight;
      const newWidth = newHeight * gameAspectRatio;
      this.netEntExtend.resize(newWidth, newHeight);
    }
  };

  dispatcher = message => {
    if (this.netEntExtend) {
      if (message.eventType === 'SET') {
        this.netEntExtend.set(
          message.event,
          message.value,
          () => null,
          () => null
        );
      } else {
        this.netEntExtend.get(
          message.event,
          eventRes => {
            this.game.checkEventData(eventRes, message.event);
          },
          () => null
        );
      }
    } else {
      if (this.gameRef.current) {
        this.gameRef.current.contentWindow.postMessage(message, '*');
      }
    }
  };

  addNetentScript = (netEntConfig, success, error) => {
    const netentScript = document.createElement('script');
    netentScript.id = 'netent-script';
    netentScript.type = 'text/javascript';
    netentScript.src = 'https://affiliate0000001-static2.casinomodule.com/gameinclusion/library/gameinclusion.js';
    netentScript.onload = () => {
      window.netent.launch(netEntConfig, success, error);
    };

    document.body.appendChild(netentScript);
  };

  startGame = config => {
    const netEntConfig = { ...config, targetElement: 'gameArea', fullScreen: true };
    const success = netEntExtend => {
      this.netEntExtend = netEntExtend;

      const newWidth = window.innerWidth;
      const newHeight = this.playableGameAreaHeight;

      netEntExtend.resize(newWidth, newHeight);
    };
    const error = e => {};

    if (document.getElementById('netent-script')) {
      window.netent.launch(netEntConfig, success, error);
      return;
    }

    this.addNetentScript(netEntConfig, success, error);
  };

  endTimerCallback = () => this.setState({ timerIsFinished: true });

  checkToRemoveListener = () =>
    this.messageHandlerFuncRef && window.removeEventListener('message', this.messageHandlerFuncRef);

  render() {
    return (
      <SGame
        navigation={this.props.navigation}
        deviceInfo={this.props.deviceInfo}
        RFValue={this.props.RFValue}
        dimension={this.props.dimension}
        render={args => {
          const { gameInterface, gameLink, gameProvider, netEntConfig, ftueStep, sneakPeekTimer, skipTutorialGame } =
            args;
          this.isSneakPeek.current = sneakPeekTimer;
          const skipTutorial = () => {
            Logger.sendEvent(EVENT_TYPES.TUTORIAL_SKIPPED, {
              id: ONBOARDING_FLOWS.CHALLENGES_ONBOARDING,
              skipped: 1,
            });
            skipTutorialGame();
          };

          // Short circuit on this.game first to prevent extra checks
          if (
            (this.game === null && gameInterface !== null) ||
            (this.game !== gameInterface && gameProvider !== this.gameProvider)
          ) {
            this.checkToRemoveListener();
            this.game = gameInterface;
            this.gameProvider = gameProvider;
            this.game.dispatcher = this.dispatcher;
            this.messageHandlerFuncRef = this.messageHandlerFunc.bind(this);
            window.addEventListener('message', this.messageHandlerFuncRef);
          }
          if (netEntConfig !== null) {
            this.startGame(netEntConfig);
          }
          return (
            <>
              <GameContainer>
                <ActivityIndicator />
                {gameLink !== null && !netEntConfig && (
                  <iframe ref={this.gameRef} src={gameLink} frameBorder="none" title="gameLink" />
                )}
                {netEntConfig && <div id="gameArea"></div>}
              </GameContainer>
              {ftueStep > FTUE_STEPS_KEYS.INITIAL && ftueStep !== FTUE_STEPS_KEYS.CLICKED_ON_SPINNER && (
                <SkipTutorialWrapper onClick={() => skipTutorial()}>
                  <LazyImage
                    tag={TAG.IMG}
                    src={assetSource(ASSET_KEY.SKIP_BUTTON)}
                    alt="Skip Tutorial"
                    style={pwaStyleAdapter({ width: 140, height: 50, objectFit: 'contain' })}
                  />
                </SkipTutorialWrapper>
              )}
              {sneakPeekTimer && sneakPeekTimer > 0 && !this.state.timerIsFinished && (
                <div className="sneakPeekTimerHolder">
                  <LazyImage tag={TAG.IMG} src={assetSource(ASSET_KEY.TIMER_DISPLAYED_SCREEN)} alt="timer" />
                  <CountdownTimer
                    counterType={counterTypes.SNEAK_PEEK}
                    endDate={sneakPeekTimer * 1000}
                    justTimerText={true}
                    endTimerCallback={this.endTimerCallback}
                    style={{ opacity: 0.9, fontWeight: 700 }}
                  />
                </div>
              )}
            </>
          );
        }}
      />
    );
  }
}

Game.defaultProps = {
  deviceInfo: null,
  RFValue: null,
  dimension: null,
};

const GameContainer = styled.div.attrs({
  className: 'GameScreen',
})`
  padding-bottom: ${isMobile ? 1.5 : 0}rem;
  margin-top: ${isNotStandaloneMobilePWA ? 2 : 0}rem;
  background-color: ${theme.palette.common[0]};
`;

const SkipTutorialWrapper = styled.div.attrs({
  className: 'FtueSkipHolder',
})`
  z-index: 9999;
  top: ${isMobile ? 120 : 70}px;
  left: ${isMobile ? 120 : 10}px;
`;

