import styled from 'styled-components';
import { Tooltip } from '..';
import { isMobile } from 'react-device-detect';

export const CHECK_OUT = {
  TOOLTIP: styled(Tooltip)`
    width: 360px;
    bottom: 160px;
    right: ${isMobile ? 'calc(41vw - 595px)' : 'calc(50vw - 595px)'};
    z-index: 100;
  `,
};

