import React from 'react';
import styled from 'styled-components';
import { isTablet } from 'react-device-detect';
import { isNotStandaloneMobilePWA } from '../../../utils/mobileSupported';
import CustomScrollBar from '../../../components/CustomScrollBar';

function FormContentContainer({ children, windowHeight }) {
  const ContentContainer = isNotStandaloneMobilePWA && !isTablet ? FormContainerScrollable : RegularFormContentWrapper;
  return <ContentContainer windowHeight={windowHeight}>{children}</ContentContainer>;
}

export default FormContentContainer;

const FormContainerScrollable = ({ children, windowHeight }) => {
  return (
    <CustomScrollBar customClassNames={`custom-scrollbar-store`} customStyle={scrollContainerCustomSyle(windowHeight)}>
      {children}
    </CustomScrollBar>
  );
};

const scrollContainerCustomSyle = windowHeight => ({
  height: windowHeight > 630 ? '95%' : '90%',
  paddingRight: 25,
});

const RegularFormContentWrapper = styled.span``;

