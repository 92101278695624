import { useEffect } from 'react';
import useCheckOrientation from './useCheckOrientation';
import { isMobile } from 'react-device-detect';

const useViewport = () => {
  const { isPortrait, isLandscape } = useCheckOrientation();

  useEffect(() => {
    const viewportMeta = document.querySelector('head meta[name="viewport"]');
    const setViewportContent = content => {
      viewportMeta.content = content;
    };

    if (!isMobile) {
      document.body.style.overflow = 'auto';
      setViewportContent(
        'width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover, maximum-scale=1.0, user-scalable=no'
      );
    } else if (isLandscape) {
      document.documentElement.setAttribute('data-pwa-mode', 'true');
      document.body.style.overflow = 'hidden';
      setViewportContent(
        `width=device-width, initial-scale=0.5, shrink-to-fit=no, viewport-fit=cover, maximum-scale=0.5, user-scalable=no, interactive-widget=resizes-content`
      );
    } else if (isPortrait) {
      setViewportContent(
        'width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1, user-scalable=no, viewport-fit=cover, interactive-widget=resizes-content'
      );
      document.documentElement.setAttribute('data-pwa-mode', 'true');
      document.body.style.overflow = 'hidden';
    }
  }, [isLandscape, isPortrait, isMobile]);
};

export default useViewport;

