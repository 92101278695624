import theme from 'shared/assets/style/theme';
import styled from 'styled-components';
import ImageBW from '../../../components/ImageBoundsWrapper';
import { pwaStyleAdapter } from '../../../utils/mobileSupported';

const linkTextStyle = {
  textDecoration: 'underline',
  color: '#a7550e',
  cursor: 'pointer',
  pointerEvents: 'auto',
};

const Header = styled.div`
  width: 100%;
  height: 70px;
`;

const HeaderContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5vmin;
  top: 1em;
`;

const HeaderImage = styled(ImageBW)`
  height: 70px;
  width: 100%;
  object-fit: contain;
  z-index: 11;
  position: absolute;
  top: 0;
  left: 0;
`;

const TitleHolder = styled.div`
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0 5vmin;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
`;

const Title = styled.p`
  font-size: ${pwaStyleAdapter(29, 'px')};
  font-weight: 600;
  color: ${theme.palette.common[1]};
  text-transform: uppercase;
  margin: 0;
`;

const HelpTextContainer = styled.div`
  cursor: pointer;
  z-index: 1;
`;

const NeedHelpStyle = {
  fontSize: pwaStyleAdapter(15),
  fontWeight: 600,
  textAlign: 'right',
  margin: '0',
};

const ModalFormBody = styled.div`
  width: 90%;
  height: 86%;
  padding: 0 1vmin;
  margin: ${window.innerHeight > 900 ? '2em auto' : '1em auto'};
`;

const FormBody = styled.div`
  width: 90%;
  max-width: 700px;
  padding: 0 1vmin;
  margin: ${window.innerHeight > 900 ? '2em auto 0 auto' : '1em auto 0 auto'};
`;

const NewUserForm = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const LeftInputContainer = styled.div`
  width: 45%;
  ${({ theme }) => theme}
`;

const RightInputContainer = styled.div`
  width: 45%;
  ${({ theme }) => theme}
`;

const FormInputContainer = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: ${window.innerHeight > 900 ? '1em' : '.5em'};
  position: relative;
`;

const Peeker = styled.div`
  position: absolute;
  top: 20%;
  right: 16px;
  z-index: 10;
  cursor: pointer;
`;

const PeekerBoxyStyle = styled.div`
  position: absolute;
  height: ${pwaStyleAdapter(42, 'px')};
  width: ${pwaStyleAdapter(30, 'px')};
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 16px;
  z-index: 2;
  cursor: pointer;
`;

const CheckBoxContainer = styled.div`
  width: 100%;
  text-align: left;
  margin: ${window.innerHeight < 300 ? '0.3em' : '2em'} auto;
`;

const CheckBox = styled.div`
  display: flex;
  text-align: left;
  margin-bottom: 0.4em;

  input[type='checkbox'] {
    width: ${pwaStyleAdapter(22, 'px')};
    height: ${pwaStyleAdapter(22, 'px')};
  }
`;

const CheckBoxText = styled.p`
  font-size: ${pwaStyleAdapter(0.9, 'em')};
  margin-left: 0.5em;
`;

const LinkText = styled.a`
  text-decoration: underline;
  color: #a7550e;
  cursor: pointer;
  pointer-events: auto;
`;

const ErrorMessageContainer = styled.div`
  color: red;
`;

const MessageContainer = styled.div`
  color: ${({ success }) => (success ? 'green' : 'red')};
  margin-bottom: 50px;
`;

const RegisterButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
`;

const RegisterButton = styled.div`
  ${({ disabledStyle }) =>
    disabledStyle &&
    `filter: grayscale(1);
  
  `}
`;

const RegisterButtonImage = styled.img`
  width: 50%;
`;

const SuccessAccountWrapper = styled(ModalFormBody)`
  padding: 0 5vmin;
  margin: 100px auto;
`;

const SuccessAccountMessage = styled(CheckBoxText)`
  color: ${theme.palette.success[1]};
`;

const InputPasswordPeeker = styled.img`
  max-width: 1.2rem;
`;

export {
  Header,
  HeaderContent,
  HeaderImage,
  TitleHolder,
  Title,
  HelpTextContainer,
  NeedHelpStyle,
  ModalFormBody,
  FormBody,
  NewUserForm,
  LeftInputContainer,
  RightInputContainer,
  FormInputContainer,
  CheckBoxContainer,
  CheckBox,
  CheckBoxText,
  LinkText,
  MessageContainer,
  ErrorMessageContainer,
  RegisterButtonContainer,
  RegisterButton,
  RegisterButtonImage,
  Peeker,
  PeekerBoxyStyle,
  linkTextStyle,
  SuccessAccountMessage,
  SuccessAccountWrapper,
  InputPasswordPeeker,
};

