import React, { useContext, useEffect, useRef, useState } from 'react';
import PanResponder from 'react-panresponder-web';
import Animated from 'animated/lib/targets/react-dom';
import SSideMenu from 'shared/components/SideMenu';
import { EVENT_TYPES } from 'shared/lib/analytics/constants';
import Logger from 'shared/lib/analytics/logger';
import { ONBOARDING_FLOWS } from 'shared/utils/onboarding';
import QueryParamsProxy from 'shared/utils/queryParamProxy';
import RouteParamConstants from 'shared/utils/routeParamConstants';
import Screens from 'shared/screens';
import audio from 'shared/utils/audio';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import ThemeContext from 'shared/context/ThemeContext';
import { FAVOURITE_ONBOARDING_STEPS } from '../OnboardingLayer/onboardingSteps';
import {
  BlackOverlay,
  Container,
  ItemButton,
  MenuContainer,
  MenuItemContainer,
  MenuItemText,
  Toggler,
} from './styledComponents';
import { isTi } from 'shared/utils/targetApplication';

const MenuItem = ({ text, isDisabled, onClick, isActive, themeContext, itemsLength }) => {
  return (
    <MenuItemContainer theme={themeContext.SideBar.MenuItem} isDisabled={isDisabled} onClick={onClick}>
      <ItemButton isActive={isActive} theme={themeContext.SideBar.ItemButton} itemsLength={itemsLength} />
      <MenuItemText theme={themeContext.SideBar.MenuItemText} data-text={text}>
        <span>{text}</span>
      </MenuItemText>
    </MenuItemContainer>
  );
};

const SideMenu = ({ onboardingStep, setOnboardingStep, setOnboardingHighlightedPosition, navigation }) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [menuWidth, setMenuWidth] = useState(0);
  const themeContext = useContext(ThemeContext);
  const menuRef = useRef();
  const favouritesOption = useRef(null);
  const prevOnboardingStepRef = useRef();

  useEffect(() => {
    if (
      onboardingStep !== prevOnboardingStepRef.current &&
      onboardingStep === FAVOURITE_ONBOARDING_STEPS.GO_TO_FAVOURITES
    ) {
      const { x, y, width, height } = favouritesOption.current.getBoundingClientRect();
      setOnboardingHighlightedPosition({ x, y, width, height }, { message: getText(TEXT_KEY.GAME_ADDED_TO_FAVS) });
    }

    prevOnboardingStepRef.current = onboardingStep;
  }, [onboardingStep, setOnboardingHighlightedPosition]);

  useEffect(() => {
    if (menuRef.current) setMenuWidth(menuRef.current?.offsetWidth);
  }, [menuRef.current?.offsetWidth, isMenuVisible]);

  useEffect(() => {
    const handleResize = () => {
      if (menuRef.current) setMenuWidth(menuRef.current?.offsetWidth);
    };

    window.addEventListener('resize', handleResize);
    window.matchMedia('(orientation: landscape)').addEventListener('change', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.matchMedia('(orientation: landscape)').removeEventListener('change', handleResize);
    };
  }, []);

  const toggleMenu = () => setIsMenuVisible(!isMenuVisible);

  const handleClick = (baseHandler, screen, args) => {
    audio.onClick();
    const { userCanFavourite, handleInformativeModal } = args;
    const isFavourites = screen === Screens.Favourites;

    if (isFavourites && !userCanFavourite) {
      return handleInformativeModal();
    }

    setIsMenuVisible(false);

    if (isFavourites && onboardingStep === FAVOURITE_ONBOARDING_STEPS.GO_TO_FAVOURITES) {
      setOnboardingHighlightedPosition({ x: 0, y: 0, width: 0, height: 0 });
      setOnboardingStep(FAVOURITE_ONBOARDING_STEPS.FINISH);
      Logger.sendEvent(EVENT_TYPES.TUTORIAL_STEP_COMPLETE, {
        id: ONBOARDING_FLOWS.FAVOURITES,
        step: 3,
      });
    }
    baseHandler(screen);
  };

  const showVipAccessFlow = screen => {
    QueryParamsProxy.setQueryParams({
      [RouteParamConstants.PARAM_MODAL]: Screens.VipAccessFlow,
      [RouteParamConstants.PARAM_NAVIGATE_TO]: screen,
    });
  };

  const showSportsAccessFlow = () => {
    QueryParamsProxy.setQueryParams({
      [RouteParamConstants.PARAM_MODAL]: Screens.SportsAccessFlow,
    });
  };

  return (
    <SSideMenu
      navigation={navigation}
      animated={Animated}
      panResponder={PanResponder}
      render={args => {
        const menuItemsConfig = [
          {
            screen: Screens.Favourites,
            text: getText(TEXT_KEY.SIDE_BAR_FAVORITE),
            onClick: () => handleClick(args.navigationTouchHandler, Screens.Favourites, args),
            isActive: document.location.href.indexOf('favourites') !== -1,
            isDisabled: !args.userCanFavourite,
          },
          {
            screen: Screens.Home,
            text: getText(TEXT_KEY.SIDE_BAR_SLOT),
            onClick: () => handleClick(args.navigationTouchHandler, Screens.Home, args),
            isActive: document.location.href.indexOf('slots') !== -1,
          },
          {
            screen: Screens.VIP,
            text: getText(TEXT_KEY.SIDE_BAR_VIP_ROOM),
            onClick: args.isVipRoomUnlocked
              ? () => handleClick(args.navigationTouchHandler, Screens.VIP, args)
              : () => showVipAccessFlow(Screens.VIP),
            isActive: document.location.href.indexOf('vip') !== -1,
          },
          {
            screen: Screens.Tables,
            text: getText(TEXT_KEY.SIDE_BAR_TABLE),
            onClick: () => handleClick(args.navigationTouchHandler, Screens.Tables, args),
            isActive: document.location.href.indexOf('table') !== -1,
          },
          ...(isTi && args.hasSportsRoom
            ? [
                {
                  screen: Screens.Sports,
                  text: getText(TEXT_KEY.SIDE_BAR_SPORTS),
                  onClick:
                    (!args.hasSportsRoomVip || args.isVipRoomUnlocked) && args.isAccountLinked
                      ? () => handleClick(args.navigationTouchHandler, Screens.Sports, args)
                      : () => showSportsAccessFlow(),
                  isActive: document.location.href.indexOf('sports') !== -1,
                },
              ]
            : []),
        ];

        return (
          <>
            <BlackOverlay onClick={isMenuVisible ? toggleMenu : undefined} isVisible={isMenuVisible} />
            <Container isMenuVisible={isMenuVisible} menuWidth={menuWidth}>
              <MenuContainer
                ref={menuRef}
                theme={themeContext.SideBar.MenuContainer}
                hasSportsRoom={args.hasSportsRoom}
              >
                {menuItemsConfig.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={item.onClick}
                    text={item.text}
                    isDisabled={item.isDisabled}
                    isActive={item.isActive}
                    themeContext={themeContext}
                    itemsLength={menuItemsConfig.length}
                  />
                ))}
              </MenuContainer>
              <Toggler isMenuVisible={isMenuVisible} alt="" onClick={toggleMenu} />
            </Container>
          </>
        );
      }}
    />
  );
};

export default SideMenu;

