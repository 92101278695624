import React from 'react';
import useSocial from 'shared/screens/Social/hooks/useSocial';
import Tab from '../../components/Tab/Tab';
import Messages from './components/Messages/Messages';
import FriendsInvite from './components/FriendsInvite/FriendsInvite';
import SocialHeaderTab from './components/SocialHeaderTab/SocialHeaderTab';
import { TEXT_KEY, getText } from 'shared/utils/localization';
import MessageContainer from './components/Messages/components/MessageContainer';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { isNotStandaloneMobilePWA } from '../../utils/mobileSupported';
import { isTablet } from 'react-device-detect';

const Social = props => {
  const useSocialShared = useSocial();
  const { count, showLoading, showFriendsFeature } = useSocialShared;
  props = { ...props, ...useSocialShared };

  if (showLoading) return <Loading />;
  if (!showFriendsFeature) return <MessageContainer component={<Messages {...props} />} />;

  return (
    <Tab>
      <Tab.Navigator headerComponent={SocialHeaderTab}>
        <Tab.Screen component={<Messages {...props} />} title={getText(TEXT_KEY.SOCIAL_MESSAGES_TITLE)} count={count} />
        <Tab.Screen component={<FriendsInvite {...props} />} title={getText(TEXT_KEY.SOCIAL_FRIENDS_INVITE_TITLE)} />
      </Tab.Navigator>
    </Tab>
  );
};

Social.AsModalProps = {
  dialogClassName: isTablet ? 'gtp-modal-social-tablet' : isNotStandaloneMobilePWA ? 'gtp-modal-social-small' : 'gtp-modal-social',
};
Social.hideBeforeSelector = true;

const Loading = () => (
  <Container>
    <Spinner animation="border" />
  </Container>
);

const Container = styled.div`
  height: ${isNotStandaloneMobilePWA ? 30 : 40}rem;
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
`;

export default Social;

